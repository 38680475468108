@font-face {
font-family: 'proximaNova';
src: url(/_next/static/media/0066ce78a7e89220-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'proximaNova';
src: url(/_next/static/media/3ff594aa3135d4e7-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: 'proximaNova';
src: url(/_next/static/media/408150a2786488b4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'proximaNova';
src: url(/_next/static/media/0dface92e5856fc0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: 'proximaNova';
src: url(/_next/static/media/e82f2e61d05059e0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'proximaNova';
src: url(/_next/static/media/e3726995b14ebbfa-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: 'proximaNova';
src: url(/_next/static/media/85e850c9685bc02f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'proximaNova';
src: url(/_next/static/media/cb1910b5edf51952-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: 'proximaNova Fallback';src: local("Arial");ascent-override: 92.82%;descent-override: 30.07%;line-gap-override: 0.00%;size-adjust: 99.12%
}.__className_c463f6 {font-family: 'proximaNova', 'proximaNova Fallback'
}.__variable_c463f6 {--font-family-primary: 'proximaNova', 'proximaNova Fallback'
}

